.ant-dropdown .ant-dropdown-menu {
    width: 150px;
    background-color: #F6F6F6 !important;
    filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.35));
}

.acaloca-nav-btn {
    border-bottom: 0px;
}

.acaloca-nav-btn:hover {
    border-bottom: 2px solid #BA92B3;
    margin-bottom: -1px;
}

.acaloca-nav-link {
    color: #F6F6F6;
}

.acaloca-nav-link:active {
    color: #BA92B3;
}

.navbar-green {
    background: #5D7840;
}

.navbar-faded {
    background: rgba(93, 120, 64,0.0);
}

.navbar-animated {
    transition-timing-function: ease-in;
    transition:0.25s;
}